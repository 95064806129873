<script lang="ts">
import { required } from '@vee-validate/rules';
import { useVModel } from '@vueuse/core';
import { Form } from 'vee-validate';
import { computed, defineComponent, PropType, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';

import { forgotPassword as forgotPasswordApi, SignInOptions } from '@/api/customer';
import Checkbox from '@/components/base/form/Checkbox.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import EmailDisplay from '@/components/login/EmailDisplay.vue';
import Captcha, { CaptchaPublicInstance } from '@/components/social/Captcha.vue';
import { useAuth } from '@/composables/useAuth';
import { UseCallback, useCallback } from '@/composables/useCallback';
import { useCheckout } from '@/composables/useCheckout';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';

interface PasswordStepData {
  password: string;
}

export default defineComponent({
  name: 'PasswordStep',
  props: {
    email: { required: true, type: String },
    isCheckout: { required: false, type: Boolean, default: false },
    modelValue: { required: true, type: String },
    submitCallback: {
      required: true,
      type: Object as PropType<UseCallback<any, [SignInOptions]>>,
    },
    user: { required: false, type: Object as PropType<{ firstName?: string; guest: boolean }> },
  },
  components: {
    Captcha,
    Checkbox,
    EmailDisplay,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    FormInput,
    ThemedButton,
  },
  emits: [
    'error-display',
    'forgot-password',
    'handle-return',
    'magic-link',
    'password-visibility',
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const { sendSignInLinkEmail } = useAuth(store);

    const passwordValue = useVModel(props, 'modelValue', emit);
    const state = reactive({
      showPassword: false,
      options: {
        gRecaptchaResponse: '',
        keepLoggedIn: true,
        preserveCart: props.isCheckout,
      },
    });

    const passwordInput = ref<{ $el: HTMLElement }>();

    const { errorMessages, validatorFailed } = useForm();
    const handleReturn = () => {
      emit('handle-return');
      passwordValue.value = '';
      props.submitCallback.reset();
    };

    const passwordFieldType = computed(() => (state.showPassword ? 'text' : 'password'));
    const toggleShowPassword = () => {
      state.showPassword = !state.showPassword;
      emit('password-visibility', { visible: state.showPassword });
    };

    const formInputAttributes = computed<Record<keyof PasswordStepData, InputAttributes>>(() => ({
      password: {
        autocomplete: 'current-password',
        placeholder: 'Password',
        name: 'password',
        type: state.showPassword ? 'text' : 'password',
      },
    }));

    const rules: Record<keyof PasswordStepData, FlexibleMessageValidator<string>> = {
      password: (value) => required(value) || errorMessages.password.required,
    };

    const { determineEntryLocation } = useCheckout(store);
    const handleSignInLink = useCallback(async () => {
      const redirectPath = props.isCheckout
        ? determineEntryLocation()
        : window.location.href.slice(window.location.origin.length);
      try {
        await sendSignInLinkEmail(props.email, redirectPath);
        emit('magic-link');
      } catch (e) {
        emit('error-display');
      }
      passwordValue.value = '';
      props.submitCallback.reset();
    });

    watch(
      () => props.submitCallback.error,
      (error) => {
        if (error) {
          passwordValue.value = '';
          if (passwordInput.value) {
            passwordInput.value.$el.focus();
          }
        }
      },
    );

    const forgotPassword = useCallback(async () => {
      passwordValue.value = '';
      props.submitCallback.reset();

      try {
        await forgotPasswordApi(props.email);
        emit('forgot-password');
      } catch (e) {
        emit('error-display');
      }
    });

    const captcha = ref<CaptchaPublicInstance>();
    const requireCaptcha = ref(false);
    const requireCaptchaOrPending = computed(
      () => requireCaptcha.value || props.submitCallback.isPending,
    );
    const onCaptchaVerified = (response: string) => {
      state.options.gRecaptchaResponse = response;
      props.submitCallback.execute(state.options);
      requireCaptcha.value = false;
    };
    const requestCaptcha = () => {
      captcha.value?.reset();
      state.options.gRecaptchaResponse = '';
      requireCaptcha.value = true;
      captcha.value?.execute();
    };

    return {
      captcha,
      forgotPassword,
      formInputAttributes,
      handleReturn,
      handleSignInLink,
      hidePasswordSvg: nutshell['img/hide_password.svg'],
      onCaptchaVerified,
      passwordFieldType,
      passwordInput,
      passwordValue,
      requestCaptcha,
      requireCaptchaOrPending,
      rules,
      showPasswordSvg: nutshell['img/show_password.svg'],
      toggleShowPassword,
      validatorFailed,
      ...toRefs(state),
    };
  },
});
</script>

<template>
  <div
    :class="{
      'opacity-50 pointer-events-none': requireCaptchaOrPending || handleSignInLink.isPending,
    }"
  >
    <p
      class="mt-6 text-base font-semibold leading-6 text-left sm:text-xl font-sofia-pro"
      aria-live="polite"
    >
      Welcome Back{{ user?.firstName ? `, ${user.firstName}` : '' }}
      <span v-if="!user?.guest" class="sr-only">
        Enter your password or use one of the options below:
      </span>
    </p>
    <EmailDisplay class="mt-6" :email="email" @change-email="handleReturn" />
    <Form v-if="!user?.guest" v-slot="{ meta: formPasswordMeta }" @submit="requestCaptcha">
      <fieldset>
        <transition name="fade">
          <div
            v-if="submitCallback.error"
            v-html="submitCallback.error.message"
            class="mt-4 font-semibold md:mt-7 text-nuts-red-800"
            role="alert"
          />
        </transition>
        <FormInput
          v-model="passwordValue"
          class="mt-6"
          :inputAttributes="formInputAttributes.password"
          showLabel
          :validator="rules.password"
          dataTest="sign-in"
        >
          <template #fallbackIcon>
            <img
              class="w-4 cursor-pointer opacity-60"
              alt="hide password"
              aria-hidden="true"
              :src="showPassword ? hidePasswordSvg : showPasswordSvg"
              @click="toggleShowPassword"
            />
          </template>
        </FormInput>
        <Checkbox
          v-model="options.keepLoggedIn"
          class="inline-flex mt-6 text-sm font-normal leading-4 sm:text-base"
          id="keepUserLoggedIn"
        >
          Remember me
        </Checkbox>
        <ThemedButton
          type="submit"
          class="w-full mt-6 cursor-pointer h-11 sm:h-12"
          :isLoading="requireCaptchaOrPending"
          theme="gray"
          :disabled="validatorFailed(formPasswordMeta) ?? false"
          data-test="sign-in-button"
        >
          Sign In
        </ThemedButton>
      </fieldset>
      <p class="mt-6 text-sm text-black sm:text-base">Forgot Password?</p>
    </Form>
    <ThemedButton
      class="mt-6 cursor-pointer h-11 sm:h-12"
      fullWidth
      :theme="user?.guest ? 'gray' : 'white'"
      type="button"
      @click="handleSignInLink.execute()"
      data-test="email-me-sign-in-link"
    >
      Email Me Sign In Link
    </ThemedButton>
    <ThemedButton
      class="mt-4 cursor-pointer h-11 sm:h-12"
      data-test="reset-password-button"
      fullWidth
      theme="white"
      type="button"
      @click="forgotPassword.execute"
    >
      {{ user?.guest ? 'Set Password' : 'Reset Password' }}
    </ThemedButton>
    <Captcha ref="captcha" @verify="onCaptchaVerified" />
  </div>
</template>

<style lang="scss" scoped>
p {
  @apply mb-0;
}
</style>
