import { Cart, CartUpdateAction, CustomLineItem, Order } from '@commercetools/platform-sdk';
import { cents, dollars, from, fromCents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';
import { storeToRefs } from 'pinia';
import { computed, Ref, ref } from 'vue';
import { Store } from 'vuex';

import { fromNutsJson } from '@/api';
import { getPaymentMethods } from '@/api/customer';
import { useCart } from '@/composables/useCart';
import { useDelivery } from '@/composables/useDelivery';
import { useDiscount } from '@/composables/useDiscount';
import type { CostSaving } from '@/composables/useProductDetail';
import { NutsLineItem } from '@/lib/cart/lineItem';
import { useAutoDelivery } from '@/stores/autoDelivery';
import { useCustomer } from '@/stores/customer';
import {
  hasOnlyGiftCertificate,
  isAdjustmentCustomLineItem,
  isGiftCertificateLineItem,
  isGiftLineItem,
  lineItemAndChildren,
  summarizeSavings,
} from '@/utils/cart';
import { CartSavingsSummary, Money } from '@/utils/money';

export interface AvailablePaymentMethod {
  type: 'ApplePay' | 'CreditAccount' | 'GiftCertificate' | 'Offline' | 'PayPal' | 'UsBankAccount';
  label: string;
  nonce?: string;
}

export interface PaymentItem {
  readonly label:
    | 'Adjustment'
    | `Auto-Delivery Discount (${number}%)`
    | 'Discount'
    | 'Duties'
    | 'Gift Certificate'
    | 'GST/HST'
    | 'Heat-Resistant Packaging'
    | 'Shipping'
    | 'Store Credit'
    | 'Subtotal'
    | 'Temporary Surcharge'
    | 'Tax';
  readonly detail?: string;
  readonly amount?: Money;
}

// 'Reshipment' is part of the list, but it will be handled
// differently in the UI and is omitted from this array
export const orderTags: string[] = [
  'Sample Order',
  'Supervisor Approved/Escalation',
  'Mail Order',
  'B2B - FE - Ship from another DC',
  'B2B - FE - Ship without',
  'CX - FE - Ship from another DC',
  'CX - FE - Ship without',
  'Delivered/Not Received',
  'Spilled/Torn Bag',
  'Damaged Bag',
  'Damaged Box',
  'Lost order',
  'Stale',
  'Rancid',
  'Foreign Object',
  'Mold/Fungus',
  'Incorrect product received',
  'Melted product',
];

export function applePayEnabled(): boolean {
  if (typeof window === 'undefined' || !window.ApplePaySession) return false;
  return window.ApplePaySession.supportsVersion(3) && window.ApplePaySession.canMakePayments();
}

export function applePayPaymentItem(
  paymentItem: PaymentItem | { amount: Money; label: 'Nuts.com' },
  type?: ApplePayJS.ApplePayLineItemType,
): ApplePayJS.ApplePayLineItem {
  return {
    amount: dollars(paymentItem.amount).toFixed(2),
    label: paymentItem.label,
    type,
  };
}

function calculateHeatResistantPackagingCentAmount(customLineItems: CustomLineItem[]) {
  const hrpCustomLineItems = customLineItems.filter((cli) =>
    cli.slug.endsWith('-heat-resistant-packaging'),
  );
  if (!hrpCustomLineItems.length) return undefined;
  return sumBy(hrpCustomLineItems, (cli) => cli.quantity * cli.money.centAmount);
}

const totalDiscountAmount = (lineItems: NutsLineItem[]) =>
  Money.sumBy(lineItems, (item) => {
    if (isGiftLineItem(item)) return from(0);
    return Money.sumBy(lineItemAndChildren(item), (itemOrChild) =>
      Money.sumBy(itemOrChild.discountedPricePerQuantity, (discount) => {
        const { discountedPrice, quantity } = discount;
        const discountedAmount = Money.sumBy(
          discountedPrice.includedDiscounts,
          (id) => id.discountedAmount,
        );
        return Money.multiply(discountedAmount, quantity);
      }),
    );
  });

const totalChannelDiscountAmount = (lineItems: NutsLineItem[]) =>
  Money.sumBy(
    lineItems.flatMap((lineItem) => lineItem.totalSavings?.breakdown ?? []),
    (b) => (b.type === 'Channel' ? b.value : from(0)),
  );

const totalProductDiscountAmount = (lineItems: NutsLineItem[]) =>
  Money.sumBy(
    lineItems.flatMap((lineItem) => lineItem.totalSavings?.breakdown ?? []),
    (b) => (b.type === 'ProductDiscount' ? b.value : from(0)),
  );

const combineSavingsAmounts = (lineItems: NutsLineItem[]) =>
  Money.sumBy(
    [
      totalDiscountAmount(lineItems),
      totalProductDiscountAmount(lineItems),
      totalChannelDiscountAmount(lineItems),
    ],
    (amount) => (cents(amount) < 0 ? Money.negate(amount) : amount),
  );

export function usePayment(
  store: Store<any>,
  cxMode: Ref<boolean>,
  alternativeCart?: Ref<Cart | Order | undefined>,
) {
  const { autoDeliveryChannel } = storeToRefs(useAutoDelivery());
  const {
    cart,
    containsAutoDelivery,
    containsProductDiscounts,
    customLineItems,
    giftCertificate,
    lineItems,
    preCheckoutLineItems,
    updateCart,
  } = useCart(store, alternativeCart);
  const customerStore = useCustomer();
  const { freeShippingThreshold } = useDelivery(store, alternativeCart);
  const { appliedDiscountCodes } = useDiscount(store, alternativeCart);

  const offlinePaymentNonce = ref<string>();

  const offerThirdPartyPayments = computed(
    () => !lineItems.value.some(isGiftCertificateLineItem) && !cxMode.value,
  );

  const paymentsByMethod = computed(
    () =>
      alternativeCart?.value &&
      ('orderState' in alternativeCart.value || undefined) &&
      groupBy(
        compact(alternativeCart.value.paymentInfo?.payments.map((p) => p.obj)),
        (p) => p.paymentMethodInfo.method,
      ),
  );

  const giftCertificateAmount = computed(() => {
    const amount = paymentsByMethod.value
      ? Money.sumBy(paymentsByMethod.value.gift_certificate, (p) => p.amountPlanned)
      : giftCertificate.value?.remainingValue;
    return amount?.centAmount ? amount : undefined;
  });

  const storeCreditAmount = computed(() => {
    const amount = paymentsByMethod.value
      ? Money.sumBy(paymentsByMethod.value.store_credit, (p) => p.amountPlanned)
      : customerStore.storeCredit?.balance;
    return amount?.centAmount ? amount : undefined;
  });

  const cartDiscountAmount = computed(() => Money.negate(totalDiscountAmount(lineItems.value)));
  const productDiscountAmount = computed(() => totalProductDiscountAmount(lineItems.value));

  const paymentItems = computed<PaymentItem[]>(() => {
    if (!cart.value) return [];

    const lineItemsTotalPrice = Money.sumBy(lineItems.value, (li) => li.totalPrice);

    const subTotalComponents = [lineItemsTotalPrice, Money.negate(cartDiscountAmount.value)];
    const subTotal = Money.sum(subTotalComponents);

    const items: PaymentItem[] = [{ label: 'Subtotal', amount: subTotal }];

    if (cents(cartDiscountAmount.value)) {
      items.push({
        label: 'Discount',
        detail: appliedDiscountCodes.value[0]?.label,
        amount: cartDiscountAmount.value,
      });
    }

    if (!hasOnlyGiftCertificate(lineItems.value)) {
      let shippingCharges;
      const shippingOfferCharges: Money[] = compact(
        cart.value.itemShippingAddresses?.map(
          (itemShippingAddress) => itemShippingAddress.custom?.fields.price,
        ),
      );

      if (shippingOfferCharges.length) {
        shippingCharges = Money.sum(shippingOfferCharges);
      } else if (cents(lineItemsTotalPrice) >= cents(freeShippingThreshold.value)) {
        shippingCharges = from(0);
      }

      items.push({ label: 'Shipping', amount: shippingCharges });
    }

    const hrpAmount = calculateHeatResistantPackagingCentAmount(customLineItems.value);
    if (typeof hrpAmount !== 'undefined') {
      items.push({ label: 'Heat-Resistant Packaging', amount: fromCents(hrpAmount) });
    }

    if (!hasOnlyGiftCertificate(lineItems.value)) {
      const [dutiesPortions, taxPortions] = partition(
        cart.value.taxedPrice?.taxPortions,
        (portion) => portion.name?.endsWith('-duties') || portion.name?.endsWith(': Duties'),
      );
      const taxAmount = taxPortions.length ? Money.sumBy(taxPortions, (t) => t.amount) : undefined;
      if (dutiesPortions.length) {
        items.push({ label: 'GST/HST', amount: taxAmount });
        items.push({ label: 'Duties', amount: Money.sumBy(dutiesPortions, (d) => d.amount) });
      } else {
        items.push({ label: 'Tax', amount: taxAmount });
      }
    }

    const adjustment = customLineItems.value.find((cli) => cli.slug === 'adjustment');
    if (adjustment) {
      items.push({ label: 'Adjustment', amount: adjustment.money });
    }

    let sum = Money.sumBy(items, (pi) => pi.amount);

    if (sum.centAmount > 0 && storeCreditAmount.value) {
      items.push({
        label: 'Store Credit',
        amount: Money.negate(Money.min(sum, storeCreditAmount.value)),
      });
    }

    sum = Money.sumBy(items, (pi) => pi.amount);

    if (sum.centAmount > 0 && giftCertificateAmount.value) {
      items.push({
        label: 'Gift Certificate',
        amount: Money.negate(Money.min(sum, giftCertificateAmount.value)),
      });
    }

    const surchargeLineItem = customLineItems.value.find((l) => l.slug === 'surcharge');
    if (surchargeLineItem) {
      items.push({
        label: 'Temporary Surcharge',
        amount: surchargeLineItem.money,
      });
    }

    return items;
  });

  const estimatedAmountToCharge = computed(() =>
    Money.sumBy(paymentItems.value, (pi) => pi.amount),
  );

  const findPaymentItem = (label: PaymentItem['label']) =>
    paymentItems.value.find((item) => item.label === label);

  const estimatedGiftCertificateCharge = computed(() => {
    const paymentItem = findPaymentItem('Gift Certificate');

    return paymentItem?.amount && Money.negate(paymentItem.amount);
  });

  const payments = computed(() =>
    flatten(Object.values(omit(paymentsByMethod.value, ['gift_certificate', 'store_credit']))),
  );

  /**
   * Subtotal of filtered line items
   *
   * We do not display Greeting Cards outside of checkout, since they can currently only be added
   * within checkout. Since a user can take no action on these line items, webstore has CT remove
   * them prior to rendering the cart displays. Once webfront mimics this behavior, we want to avoid
   * a race condition on display.
   *
   * TODO: rename this to `lineItemsTotalAmount` in the event that webfront is capable of managing
   * greeting card associations externally, as "pre checkout" should no longer apply.
   */
  const preCheckoutSubtotal = computed(() =>
    Money.sumBy(preCheckoutLineItems.value, (item) => item.totalPriceBeforeCartLevelDiscount),
  );

  /**
   * Pre-checkout estimated total
   *
   * Similar to `preCheckoutSubtotal`, we do not factor all costs into display outside checkout.
   * Until we can use `paymentItems` directly, this should be a substitute for display of user's cart
   * total.
   */
  const estimatedTotal = computed(() =>
    Money.sum([preCheckoutSubtotal.value, cartDiscountAmount.value]),
  );

  const savingsBreakdown = computed(() => {
    const discounts: CostSaving[] = [];
    if (containsAutoDelivery.value) {
      discounts.push({
        label: `${autoDeliveryChannel.value.discount}% off Auto-Delivery`,
        type: 'auto-delivery',
      });
    }
    if (dollars(cartDiscountAmount.value) !== 0)
      discounts.push({
        label: appliedDiscountCodes.value[0]?.label,
        type: 'cart',
      });
    if (containsProductDiscounts.value) {
      discounts.push({
        label: 'Sale Discount',
        type: 'product',
      });
    }
    return discounts;
  });

  const totalSavings = computed<CartSavingsSummary | undefined>(() =>
    summarizeSavings(
      lineItems.value,
      cartDiscountAmount.value && Money.negate(cartDiscountAmount.value),
      appliedDiscountCodes.value[0]?.label,
    ),
  );

  const generatePaymentAdjustmentRemoveAction = (): CartUpdateAction | undefined => {
    const adjustment = customLineItems.value.find(isAdjustmentCustomLineItem);
    if (!adjustment) return undefined;
    return {
      action: 'removeCustomLineItem',
      customLineItemId: adjustment.id,
    };
  };

  return {
    cartDiscountAmount,
    combineSavingsAmounts,
    estimatedAmountToCharge,
    estimatedGiftCertificateCharge,
    estimatedTotal,
    findPaymentItem,
    offerThirdPartyPayments,
    paymentItems,
    payments,
    preCheckoutSubtotal,
    productDiscountAmount,
    savingsBreakdown,
    totalSavings,

    determineAvailablePaymentMethods() {
      return computed<AvailablePaymentMethod[]>(() => {
        const hasAD = containsAutoDelivery.value;
        const hasGC = lineItems.value.some(isGiftCertificateLineItem);
        return compact([
          offerThirdPartyPayments.value && { type: 'PayPal', label: 'Pay with' }, // logo inferred by type
          offerThirdPartyPayments.value &&
            applePayEnabled() && { type: 'ApplePay', label: 'Pay with' }, // logo inferred by type
          !hasAD &&
            !hasGC &&
            customerStore.creditAccount && {
              type: 'CreditAccount',
              label: 'Credit Account',
              nonce: customerStore.creditAccount.nonce,
            },
          !hasAD &&
            cxMode.value &&
            offlinePaymentNonce.value && {
              type: 'Offline',
              label: 'Offline',
              nonce: offlinePaymentNonce.value,
            },
          !hasAD && !hasGC && { type: 'GiftCertificate', label: 'Gift Certificate' },
        ]);
      });
    },

    async getSavedPaymentMethods() {
      const customerId = cxMode.value ? customerStore.customer?.id : 'me';
      const { paymentMethods } = await fromNutsJson(getPaymentMethods(customerId));

      offlinePaymentNonce.value = paymentMethods.find((p) => p.type === 'Offline')?.nonce;

      return paymentMethods.filter((p) => p.type !== 'Offline');
    },
    hasPaymentAdjustment: computed(() =>
      customLineItems.value.some(() => isAdjustmentCustomLineItem),
    ),
    removePaymentAdjustment: async () => {
      await updateCart(() => [generatePaymentAdjustmentRemoveAction()]);
    },
    setPaymentAdjustment: async (money: Money, reason?: string) =>
      updateCart(() => {
        const actions: CartUpdateAction[] = [];

        const removeAdjustmentAction = generatePaymentAdjustmentRemoveAction();
        if (removeAdjustmentAction) actions.push(removeAdjustmentAction);

        const shipmentKey = cart.value!.itemShippingAddresses![0]!.key || 'shipment-1';

        actions.push({
          action: 'addCustomLineItem',
          name: { en: 'Adjustment' },
          quantity: 1,
          money,
          slug: 'adjustment',
          custom: {
            type: { typeId: 'type', key: 'adjustmentCustomLineItem' },
            fields: { adjustmentReason: reason },
          },
          shippingDetails: {
            targets: [
              {
                addressKey: shipmentKey,
                quantity: 1,
                shippingMethodKey:
                  cart.value?.shippingMode === 'Multiple' ? shipmentKey : undefined,
              },
            ],
          },
        });
        return actions;
      }),
  };
}
